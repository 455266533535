import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="Simon Davies / Page Not Found - 404"
      description="Ouch! Seems your looking for something..."/>
    <div className="flex justify-center items-center absolute w-screen max-w-full h-screen max-h-full top-0 left-0 z-10">
      <div className="flex justify-center items-end absolute w-screen max-w-full h-screen max-h-full top-0 left-0 z-10 m-0 p-12 pb-24 md:p-12">
        <div className="flex flex-col md:flex-row justify-between items-center m-0 p-0 w-full">
          <div className="title mb-4 md:mb-0 text-center md:text-left">
            <h1 className="m-0 mb-1 p-0 uppercase text-xl font-thin font-sans text-gray-400 tracking-title">Page Not Found</h1>
            <p className="m-0 mb-1 p-0 text-sm font-thin font-sans text-gray-400 tracking-widest">Ouch! Seems your looking for something...</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
